// clean-up previous instance, if any
if (typeof(mymap) != 'undefined') {
	mymap.off();
	mymap.remove();
}

mymap = L.map('mapid').setView([46.5393, 2.42876], 6);
gpx_default_color = "#5D606A";
gpx_mouseover_color = "#3366CC"; // from Leaflet lightblue-theme
gpx_selected_color = "#3366CC"; // from Leaflet lightblue-theme

L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
	attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
	maxZoom: 18,
}).addTo(mymap);

marker_group = L.layerGroup();
water_group = L.layerGroup();
restaurants_group = L.layerGroup();
trainstations_group = L.layerGroup();
hotels_group = L.layerGroup();

var baseLayers = {
};
var overlays = {
    "étapes": marker_group,
    "points d'eau": water_group,
    "restaurants": restaurants_group,
    "gares": trainstations_group,
    "hôtels": hotels_group,
};
L.control.layers(baseLayers, overlays, {collapsed: false, position:'topleft'}).addTo(mymap);

L.control.scale().addTo(mymap);

loop = false;
selected_gpx = null;

var polyline_options = {
	color: gpx_default_color,
	opacity: 0.70,
	weight: 4,
	lineCap: 'round'
};
var marker_options = {
	startIconUrl: require('images/1x1.png'),
	endIconUrl:   require('images/1x1.png'),
	shadowUrl:    require('images/1x1.png')
};

function addSelectVariant(id) {
	var x = document.getElementById("variant_span");
	x.innerHTML += '<div class="form-group">';

	var select = document.createElement("select");
	select.classList.add("form-control");
	select.classList.add("form-control-sm");
	select.id = "variant["+id+"]"
	select.name = "variant["+id+"]"
	x.appendChild(select);

	x.innerHTML += '</div>';
}
function hideSelectVariant(id) {
	var x = document.getElementById("variant["+id+"]");
	x.hidden = true;
}
function addListenerSelectVariant(id, is_start_city) {
	var x = document.getElementById("variant["+id+"]");
	x.addEventListener('change', function() {
		var gpx_path = '../variant/cities/'+x.value;
		$.ajax({url: gpx_path, async: true, success: function(result) {
			var metadata = result;
			if (is_start_city) {
				$("#start_city").val(metadata['start']['city']);
			}
			else {
				$("#end_city").val(metadata['end']['city']);	
			}
		}});
	});
}
function addOptionVariant(variant_id, value, text) {
	var x = document.getElementById("variant["+variant_id+"]");
	var option = document.createElement("option");
	option.value = value;
	option.text = text;
	x.add(option);
}
function removeSelectVariants() {
	document.getElementById("variant_span").innerHTML = "";
}

function gpxSetDefaultColor(gpx) {
	gpx.setStyle({
		color: gpx_default_color
	});
}
function gpxSetMouseoverColor(gpx) {
	gpx.setStyle({
		color: gpx_mouseover_color
	});
}
function gpxSetSelectColor(gpx) {
	gpx.setStyle({
		color: gpx_selected_color
	});
}
function gpxAddOneColorListeners(gpx) {
	gpxSetDefaultColor(gpx);
	gpx.addEventListener('mouseover', function() {
		gpxSetMouseoverColor(this);
	});
	gpx.addEventListener('mouseout', function() {
		gpxSetDefaultColor(this);
	});
}
function gpxAddColorListeners(map) {
	map.eachLayer(function(layer){
	    if (typeof(layer._gpx)!="undefined") {
	    	gpxAddOneColorListeners(layer);
	    }
	});
}

// GPX listener
function addGPXListeners(e, imported = false) {
	gpx = e.target;
	// color listeners
	gpxAddOneColorListeners(gpx);
	// click listeners
	gpx.addEventListener('click', function() {
		if (selected_gpx != $(this)[0]) {
			selected_gpx = $(this)[0];
			removeSelectVariants();
			if (imported) {
				$('#tracefile_filename').val('tmp/'+imported_gpx_filename+'.gpx');
			}
			else {
				$('#tracefile_filename').val(selected_gpx._gpx.replace('/gpx/',''));
			}

			// zoom on GPX
			mymap.fitBounds(selected_gpx.getBounds());
			// if necessary, reset default colors on other GPX
			gpxAddColorListeners(mymap);
			// color the GPX
			gpxSetMouseoverColor(this);
			// lock the color
			this.addEventListener('mouseout', function() {
				gpxSetMouseoverColor(this);
			});

			// valeurs par défaut pour le formulaire
			if (path_gpx == '/gpx/bike') {
				$('#km_per_stage').val('60');
				$('#ele_per_stage').val('800');
			}
			else {
				$('#km_per_stage').val('10');
				$('#ele_per_stage').val('1000');						
			}
			
			// metadonnées sur le GPX
			if (imported) {
				var gpx_path = '../gpx/info/tmp/'+imported_gpx_filename+'.gpx';
			}
			else {
				var gpx_path = '../gpx/info/'+selected_gpx._gpx.replace('/gpx/','');
			}
			$.ajax({url: gpx_path, async: true, success: function(result) {
				var metadata = result;
				$("#tracefile_name").val(metadata['name']);
				$("#start_city").val(metadata['start']['city']);	
				$("#end_city").val(metadata['end']['city']);
				$("#form_traces_new").prop('disabled', false);
				loop = metadata['loop'];
			}});

			// gestion des choix
			if (gpx_path.includes("-options-short.gpx") && !imported) {
				// suppression des anciens choix
				removeSelectVariants();
				// affichage des choix possibles dans le formulaire
				$.get('../gpx/options/'+path_gpx.split('/')[2]+'/'+gpx_path.split('/')[4], '', function(options_list){
					for (var i = 0; i < options_list.length; i += 1) {
						addSelectVariant(i);
						for (var j = 0; j < options_list[i].length; j += 1) {
							addOptionVariant(i, options_list[i][j]["filename"], options_list[i][j]["name"]);
						}
						// si une seule option, on masque le choix
						if (j == 1) {
							hideSelectVariant(i);
						}
					}
					// si changement sur premier ou dernier choix, rafraîchissement des villes de départ ou arrivée
					addListenerSelectVariant(0, true);
					addListenerSelectVariant(options_list.length-1, false);
				});
			}
		}
	});
	
}

// leaflet.elevation
var elevation_options = {
    theme: "lightblue-theme",
    detached: false,
    elevationDiv: "#elevation-div",
    autohide: false,
    collapsed: true,
    position: "topright",
    followMarker: false,
    imperial: false,
    reverseCoords: false,
    height: 150,
    legend: false,
    summary: false,
    downloadLink: false
 };
controlElevation = L.control.elevation(elevation_options).addTo(mymap);

// leaflet.filelayer
L.Control.FileLayerLoad.LABEL = '<img class="icon" src='+require('images/folder-open-solid.svg')+' alt="file icon"/>';
L.Control.FileLayerLoad.TITLE = 'Importer un fichier (GeoJSON, KML ou GPX)';
controlFile = L.Control.fileLayerLoad({
    // Allows you to use a customized version of L.geoJson.
    // For example if you are using the Proj4Leaflet leaflet plugin,
    // you can pass L.Proj.geoJson and load the files into the
    // L.Proj.GeoJson instead of the L.geoJson.
    layer: L.geoJson,
    // See http://leafletjs.com/reference.html#geojson-options
    layerOptions: {style: {color:'red'}},
    // Add to map after loading (default: true) ?
    addToMap: true,
    // File size limit in kb (default: 1024) ?
    // Adjust reverse proxy accordingly if there is one, e.g. client_max_body_size for Nginx
    fileSizeLimit: 2048,
    // Restrict accepted file formats (default: .geojson, .json, .kml, and .gpx) ?
    formats: [
        '.geojson',
        '.kml',
        '.gpx'
    ]
});
controlFile.addTo(mymap);
controlFile.loader.on('data:loaded', function (e) {
	var geojson_object = e;
	gpx_data = togpx(geojson_object.layer.getLayers()[0].feature);
	geojson_object.layer.remove();
	gpx_imported = new L.GPX(gpx_data, {
		polyline_options: polyline_options,
		async: true,
		marker_options: marker_options,
	}).on('loaded', function(e) {
		addGPXListeners(e, true);
		let formData = new FormData()
		formData.append('file', gpx_data);
		fetch('/gpx/import', {
			method: 'POST',
			body: formData
		}).then(function(response) {
			resp = response.json();
			resp.then((value) => {
				imported_gpx_filename = value;
			});
		});
	}).addTo(mymap);
});
controlFile.loader.on('data:error', function (e) {
	$('#import-error').append(e.error.message);
	$('#import-error').show();
});

path_gpx = '/gpx/'+window.location.pathname.split('/')[1];
$.get(path_gpx, '', function(gpx_list){
	for (var i = 0; i < gpx_list.length; i += 1) {
		var tracefile_filename = gpx_list[i];
		new L.GPX(tracefile_filename, {
			polyline_options: polyline_options,
			async: true,
			marker_options: marker_options,
		}).on('loaded', function(e) {
			addGPXListeners(e);
		}).addTo(mymap);
	}
});
